










import Vue from "vue";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";
import { displayItemsWithSku } from "@/utils/marketItemDisplay";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      importItems: [],
      insertDialogContainer: new DataContainer(),
    };
  },
  methods: {
    makeTableOptions() {
      const self = this;
      const self2 = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "Thay đổi cách bán",
          urlEnabled: true,
          search: {},
          displayFields: {
            _id: {
              text: "ID",
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            priority: {
              text: "Độ ưu tiên",
              sortable: true,
            },
            exchangeRate: {
              text: "Tỉ giá",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            time: {
              text: "Thời gian",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            markets: {
              text: "Thị trường",
              options: {
                labels: true,
                labelItems(value) {
                  return value.name;
                },
              },
            },
            products: {
              text: "Sản phẩm",
              options: {
                labels: true,
                labelItems(value) {
                  return value.sku || value.name;
                },
              },
            },
            description: {
              text: "Mô tả",
            },
            proceed: {
              text: "Đã xử lý",
              options: {
                boolean: true,
              },
            },
            action: {},
          },
          filters: {
            filters: {
              name: {
                attrs: {
                  label: "Name",
                },
                rules: {
                  match: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày (kết thúc)",
                    },
                  },
                },
              },
              marketIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiWorkerClient.call(
                          "markets",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              search,
                              limit,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              productIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiWorkerClient.call(
                          "products",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              search,
                              limit,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return displayItemsWithSku(items);
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiWorkerClient.call(
                                  "costModifyCommands",
                                  "importPrepare",
                                  {
                                    srcMarketId: data.srcMarketId,
                                    targetMarketId: data.targetMarketId,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                              self2.importItems = result;
                              self2.insertDialogContainer.value.show();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insert: userManager.checkRole(["kt"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                  content: {
                    content: {
                      attrs: {
                        xOptions: {
                          content: {
                            fields: {
                              items: {
                                ext: {
                                  defaultValue() {
                                    return self.importItems;
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  on: {
                    xHidden() {
                      self.importItems = [];
                    },
                  },
                  ext: {
                    container: self.insertDialogContainer,
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: (item) =>
                        `${item.name} - ${new Date(
                          item.time
                        ).toLocaleString()} }`,
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiWorkerClient.call(
                "costModifyCommands",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              return await coreApiWorkerClient.call(
                "costModifyCommands",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiWorkerClient.call(
                "costModifyCommands",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiWorkerClient.call(
                "costModifyCommands",
                "delete",
                {
                  id: item._id,
                }
              );
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                },
                ext: {
                  colLength: 6,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
                ext: {
                  colLength: 6,
                },
              },
              time: {
                type: "XTimePicker",
                attrs: {
                  label: "Thời gian",
                  required: true,
                },
                ext: {
                  colLength: 4,
                },
              },
              priority: {
                type: "number",
                attrs: {
                  label: "Độ ưu tiên",
                  required: true,
                },
                ext: {
                  colLength: 4,
                },
              },
              exchangeRate: {
                type: "number",
                attrs: {
                  label: "Tỷ giá",
                },
                ext: {
                  colLength: 4,
                },
              },
              marketIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiWorkerClient.call(
                          "markets",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              search,
                              limit,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              productIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiWorkerClient.call(
                          "products",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              search,
                              limit,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return displayItemsWithSku(items);
                      },
                    },
                  },
                },
              },
              proceed: {
                type: "boolean",
                attrs: {
                  label: "Đã xử lý",
                },
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "Danh sách chuyển giá",
                  xOptions: {
                    content: {
                      itemLabel: (item) => {
                        return `${item.localPrice || 0} => ${
                          item.localShippingCost || 0
                        }`;
                      },
                      itemProperties: {
                        localPrice: {
                          type: "number",
                          attrs: {
                            label: "Giá bán nội địa",
                            required: true,
                          },
                        },
                        localShippingCost: {
                          type: "number",
                          attrs: {
                            label: "Chi phí ship nội địa",
                            required: true,
                          },
                        },
                        revenue: {
                          type: "number",
                          attrs: {
                            label: "DSDK MKT tối ưu",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                            ignoreOtherFields: false,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "time",
                  "priority",
                  "exchangeRate",
                  "marketIds",
                  "productIds",
                  "items",
                ],
              },
            },
            editForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "time",
                  "priority",
                  "exchangeRate",
                  "marketIds",
                  "productIds",
                  "proceed",
                  "items",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "_id",
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
